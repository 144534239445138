.header {
  background-color: rgba(59, 130, 246, 0.5);
  display: flex;
  padding: 13px 12px;
  justify-content: space-evenly;
}

.form {
  width: 1266px;
}

.overflow {
  overflow: auto;
  margin-bottom: 20px;
}

.body {
  padding: 10px;
  padding-top: 0;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  background-color: #e4e4e7;
  /* margin-bottom: 20px; */
  align-items: flex-start;
  background: #fff;
  border: 1px solid rgb(216, 220, 225);
  min-width: 900px;
  max-width: 1100px;
}

.picture-container {
  text-align: center;
  width: 230px;
  height: 230px;
  background-color: #e4e4e7;
  border-radius: 5px;
}

.picture-text {
  /* text-align: center; */
  color: black;
  font-size: 13px;
  margin-left: 10px;
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.picture-img {
  margin: 10px;
  height: 177px;
  width: 210px;
  border-radius: 5px;
  margin-bottom: 0;
}

.colvo-ob {
  text-align: end;
  color: black;
  font-size: 20px;
  margin-bottom: 10px;
}

.container-yacheek {
  max-width: 427px;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
}

.day-list {
  display: flex;
  max-width: 427px;
  margin: auto;
  justify-content: space-around;
}

.day-list small {
  text-align: center;
  width: 55px;
}

.yacheika {
  border-radius: 9px;
  margin: 3px;
  width: 55px;
  height: 55px;
  background-color: #e4e4e7;
  border-radius: 5px;
}

.yacheika-text {
  color: gray;
  margin-top: 2px;
  margin-right: 3px;
  text-align: right;
  font-size: 13px;
}

.body .input-checkbox {
  height: auto;
  padding-top: 35px;
}

.input {
  width: 28px;
  margin-left: 10px;
}

.checkbox-container {
  font-size: 13px;
  text-align: center;
  padding-right: 10px;
}
.select-container {
  font-size: 13px;
  text-align: center;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.buttom {
  width: 200px;
  margin-top: 7px;
  height: 46px;
}
.buttom-container {
  display: flex;
  flex-direction: column;
}

.MuiOutlinedInput-notchedOutline {
  border-color: rgb(0 0 0 / 0%) !important;
}
/* @media (max-width: 1247px) {
  .input-checkbox {
    width: 149px;
    height: 149px;
  }
  .picture-container {
    width: 149px;
    height: 149px;
  }
  .input-checkbox {
    width: 125px;
    height: 125px;
  }
  .yacheika {
    width: 40px;
    height: 40px;
  }
  .container-yacheek {
    max-width: 460px;
  }
  .picture-text {
    font-size: 9px;
  }
  .picture-img {
    height: 115px;
    width: 130px;
  }
  .checkbox-container {
    font-size: 9px;
  }
  .input {
    margin-top: 0;
  }
} */

.checkbox {
  width: 45px;
}

.checkbox-label {
  margin-top: 7px;
}

.MuiInputBase-input {
  box-sizing: border-box !important;
  padding: 0;
}

.PrivateNotchedOutline-root-1 {
  border-width: 0 !important;
}

.MuiInput-underline:after {
  border-bottom: none !important;
}

.MuiInputBase-input {
  --tw-ring-shadow: none !important;
}

.MuiOutlinedInput-input {
  --tw-ring-shadow: none !important;
}

.main {
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media (min-width: 1241px) {
  .main {
    flex-direction: column;
    display: flex;
    /* align-items: baseline; */
  }
}
