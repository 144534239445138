.containerTitlePhoto {
  border: 1px solid #c6cace;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  flex-wrap: wrap;
  background-color: rgb(241 245 250);
  overflow-x: auto;
  top:5px;
  box-shadow:1px 1px 13px 1px rgba(0,0,0,0.3);
}

.headerTitlePhoto {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  width: 100%;
}

.iconTitlePhoto {
  cursor: pointer;
  width: 20px;
  height: 20px;
  float: right;
}

.imageTitlePhoto {
  margin: 10px;
  width: 100px;
  height: 100px;
  cursor: unset;
}
